import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import userManager from "../../auth/oidcConfig";

export default function Home() {
    
const logout = async () => {
    const user = await userManager.getUser();
    userManager.signoutRedirect({ id_token_hint: user.id_token });
  };
  
    const [user, setUser] = useState(null);

    useEffect(() => {
        userManager.getUser().then(user => {
            if (!user)
                userManager.signinRedirect()
            setUser(user)
        });
    }, []);

    return (
        <div>
            <h2>Home</h2>
            <p>Welcome, {JSON.stringify(user?.profile?.name)}!</p>
            <button onClick={logout}>Logout</button>
            <div>
                <Link to="/ApiResource">ApiResource</Link>
            </div>
            <div>
                <Link to="/ApiScope">ApiScope</Link>
            </div>
            <div>
                <Link to="/Client">Client</Link>
            </div>
            <div>
                <Link to="/IdentityProvider">IdentityProvider</Link>
            </div>
            <div>
                <Link to="/IdentityResource">IdentityResource</Link>

            </div>
        </div>
    );
};
