// src/api/axiosInstance.js
import axios from 'axios';
import { setupRequestInterceptor } from './interceptors/requestInterceptor';
import { setupResponseInterceptor } from './interceptors/responseInterceptor';

const axiosInstance = axios.create({
  baseURL: 'https://apisys.thienvan.io.vn', // Replace with your API base URL
});

setupRequestInterceptor(axiosInstance);
setupResponseInterceptor(axiosInstance);

export default axiosInstance;
