import React, { Fragment, useEffect, useState } from 'react';
import Create from '../../../components/Create';
import { Button, Col, Form, Row, Flex, Input } from 'antd';
import AppInput from '../../../components/Control/ControlInput';
import AppDatePicker from '../../../components/Control/ControlDatePicker';


const descProp = {
  Properties: 'Thuộc tính này chứa danh sách các đối tượng ClientProperty cho phép lưu trữ các thuộc tính tùy chỉnh liên quan đến client. Những thuộc tính này có thể được sử dụng để lưu trữ dữ liệu tùy chỉnh liên quan đến cấu hình của client.',
}

export default function CreateIdentityResource() {

    const elements = [
        [{ col: 8, control: 'ControlInput', name: 'id', label: 'id', key: true },],
        [
            { col: 8, control: 'ControlInput', name: 'scheme', label: 'scheme' },
            { col: 8, control: 'ControlInput', name: 'displayName', label: 'displayName' },
            { col: 4, control: 'ControlCheckBox', name: 'required', label: 'required', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'showInDiscoveryDocument', label: 'showInDiscoveryDocument', valuePropName: "checked" },
        ],
        [
            { col: 8, control: 'ControlInput', name: 'type', label: 'type' },
            { col: 8, control: 'ControlInput', name: 'properties', label: 'properties' },
            { col: 4, control: 'ControlCheckBox', name: 'emphasize', label: 'emphasize', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'requireResourceIndicator', label: 'requireResourceIndicator', valuePropName: "checked" },
        ],
        [
            { col: 8, control: 'ControlSelect', name: 'refreshTokenUsage', label: 'refreshTokenUsage', items: [{ value: 0, label: 'ReUse' }, { value: 1, label: 'OneTimeOnly' }] },
            { col: 8, control: 'ControlSelect', name: 'refreshTokenExpiration', label: 'refreshTokenExpiration', items: [{ value: 0, label: 'Sliding' }, { value: 1, label: 'Absolute' }] },
            { col: 8, control: 'ControlSelect', name: 'accessTokenType', label: 'accessTokenType', items: [{ value: 0, label: 'Jwt' }, { value: 1, label: 'Reference' }] },
        ], [
            {
                col: 24, control: 'List', name: 'secrets', label: 'Secrets',
                items: [
                    [
                        { col: 8, control: 'ControlInput', name: 'type', label: 'type' },
                        { col: 8, control: 'ControlInput', name: 'value', label: 'value' },
                        { col: 8, control: 'ControlDatePicker', name: 'expiration', label: 'expiration' },
                    ], [
                        { col: 24, control: 'ControlInput', name: 'description', label: 'description', multiline: true, rows: 4 },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'properties', label: 'Properties',
                items: [
                    [
                        { col: 12, control: 'ControlInput', name: 'key', label: 'key' },
                        { col: 12, control: 'ControlInput', name: 'value', label: 'value' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'userClaims', label: 'UserClaims',
                items: [
                    [
                        { col: 12, control: 'ControlInput', name: 'key', label: 'key' },
                        { col: 12, control: 'ControlInput', name: 'value', label: 'value' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'scopes', label: 'Scopes',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'allowedAccessTokenSigningAlgorithms', label: 'AllowedAccessTokenSigningAlgorithms',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
        ]
    ]

    return (<Fragment>
        <Create elements={elements}>
        </Create>
    </Fragment>
    )
}
