import { Button, Col, Flex, Form, Row, Table } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';
import axiosInstance from '../../api/axiosInstance';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';


const CustomFilter = React.forwardRef(({ value, ...props }, ref) => {

    return (
        <React.Fragment>
            <TextField label={props.label} variant="standard" ref={ref} value={value} {...props} />
        </React.Fragment>
    );
});


export default function ListData(props) {
    const { columns, urlListData } = props;
    const lstfilterBy = columns.filter(x => x?.isfilterby == true).map(x => {
        return { ...x, value: x.dataIndex, label: x.title }
    });

    const lstfilter = columns.filter(x => x?.isfilter == true && (x.filterby == null || x.filterby == false)).map(x => {
        return { ...x, value: x.dataIndex, label: x.title }
    });

    const [form] = Form.useForm();

    const [dataColumn, setDataColumn] = useState(columns);

    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [data, setData] = useState();
    const [filterBy, setFilterBy] = useState(null);

    useEffect(() => {
        setDataColumn(columns);
    }, [columns]);
    useEffect(() => {
        if (lstfilterBy && filterBy == null)
            setFilterBy(lstfilterBy[0]?.value)
    }, [lstfilterBy])

    const fetchData = () => {
        const callData = async () => {
            try {
                // Example using apiClient1
                setLoading(true);
                let values = form.getFieldsValue();
                values = Object.fromEntries(
                    Object.entries(values).filter(([_, value]) => value !== null && value !== '' && value !== undefined)
                );
                const queryString = new URLSearchParams(values).toString();

                const { data } = await axiosInstance.get(`${urlListData}?${queryString}`);
                setData(data); // or combine data as needed
                setLoading(false);
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        total: 200,
                        // 200 is mock data, you should read it from server
                        // total: data.totalCount,
                    },
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        callData();
    };

    useEffect(() => {
        fetchData();
    }, [
        tableParams.pagination?.current,
        tableParams.pagination?.pageSize,
        tableParams?.sortOrder,
        tableParams?.sortField,
        JSON.stringify(tableParams.filters),
    ]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
            sortField: Array.isArray(sorter) ? undefined : sorter.field,
        });

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setData([]);
        }
    };

    const handleDelete = async (id) => {
        await axiosInstance.delete(`/api/clients/${id}`)
    }
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        fetchData()
    }

    const CustomFilterBy = React.forwardRef(({ value, ...props }, ref) => {

        return (
            <React.Fragment>
                <TextField label="Từ khóa" variant="standard" ref={ref} value={value} {...props} />
                <FormControl variant="standard" sx={{ minWidth: 50 }}>
                    <InputLabel >Tìm theo</InputLabel>
                    <Select
                        autoWidth
                        value={filterBy}
                        onChange={(event) => { setFilterBy(event.target.value) }}
                        label="Tìm theo"
                    >
                        {
                            lstfilterBy?.map((v, i) => {
                                return <MenuItem value={v.value}>{v.label}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </React.Fragment>
        );
    });

    return (
        <>
            <Form
                style={{ margin: '12px' }}
                form={form}
                name="register"
                onFinish={onFinish}
            >
                {
                    (lstfilterBy && lstfilterBy.count > 0) &&
                    <Form.Item name={filterBy}>
                        <CustomFilterBy />
                    </Form.Item>
                }
                {
                    (lstfilter && lstfilter.count > 0) &&
                    <Flex gap={'middle'}>
                        {
                            lstfilter?.map((v, i) => {
                                return <Form.Item name={v.value}>
                                    <CustomFilter label={v.label} />
                                </Form.Item>
                            })
                        }
                    </Flex>
                }
                <Flex gap={'middle'}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Search</Button>
                    </Form.Item>
                </Flex>
            </Form>

            <Table
                columns={dataColumn}
                rowKey={(record) => record.Id}
                dataSource={data}
                // pagination={tableParams.pagination}
                loading={loading}
                onChange={handleTableChange}
            />
        </>

    );
}
