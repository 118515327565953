// src/api/interceptors/responseInterceptor.js
export const setupResponseInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        // Process response data
        return response;
      },
      (error) => {
        // Handle errors
        if (error.response.status === 401) {
          // Handle unauthorized errors, redirect to login, etc.
        }
        return Promise.reject(error);
      }
    );
  };
  