// src/Callback.js
import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../api/axiosInstance';
import ListData from '../../../components/List';
import { Link } from 'react-router-dom';

export default function IdentityProvider() {

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      isfilterby: true
    },
    {
      title: 'scheme',
      dataIndex: 'scheme',
      sorter: true,
      width: '20%',
      isfilterby: true,
      render: (text,rc) => <Link to={`/IdentityProvider/${rc?.id}`}>{rc?.scheme}</Link>
    },
    {
      title: 'displayName',
      dataIndex: 'displayName',
      isfilter: true,
    },
    {
      title: <><Button><Link to={'/IdentityProvider/-1'}>Add</Link></Button></>,
      dataIndex: '',
      key: 'x',
      render: (text,rc) => <Button onClick={() => handleDelete(rc.id)}>Delete</Button>,
    },
  ];
  const handleDelete = async (id) => {
    await axiosInstance.delete(`/api/IdentityProviders/${id}`)
  }
  return (
    <ListData columns={columns} urlListData={'/api/IdentityProviders'} />
  );
};
