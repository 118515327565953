// src/Callback.js
import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../api/axiosInstance';
import ListData from '../../../components/List';
import { Link } from 'react-router-dom';

export default function APIResource() {

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      render: (text, rc) => <Link to={`/APIResource/${rc?.id}`}>{rc?.name}</Link>,
      width: '20%',
    },
    {
      title: 'displayName',
      dataIndex: 'displayName',
    },
    {
      title: <><Button><Link to={'/APIResource/-1'}>Add</Link></Button></>,
      dataIndex: '',
      key: 'x',
      render: (text, rc) => <Button onClick={() => handleDelete(rc.id)}>Delete</Button>,
    },
  ];

  const handleDelete = async (id) => {
    await axiosInstance.delete(`/api/APIResources/${id}`)
  }

  return (
    <ListData columns={columns} urlListData={'/api/APIResources'} />
    // <Table
    //   columns={columns}
    //   rowKey={(record) => record.employeeId}
    //   dataSource={data}
    //   pagination={tableParams.pagination}
    //   loading={loading}
    //   onChange={handleTableChange}
    // />
  );
};
