// src/auth/AuthProvider.js
import React, { createContext, useState, useEffect } from 'react';
import userManager from './oidcConfig';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userManager.getUser().then((user) => {
      setUser(user);
      setLoading(false);
    });

    userManager.events.addUserLoaded((user) => {
      setUser(user);
    });

    userManager.events.addUserUnloaded(() => {
      setUser(null);
    });
  }, []);

  const login = () => {
    userManager.signinRedirect();
  };

  const logout = () => {
    userManager.signoutRedirect();
  };

  const contextValue = {
    user,
    loading,
    login,
    logout,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
