import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axiosInstance from '../../../api/axiosInstance';
import Create from '../../../components/Create';
import { Card } from 'antd';
import { Button } from '@mui/material';

export default function CreateAPIResource() {
    const { id } = useParams();
    const onFinish = (values) => {
        if (id != "-1")
            axiosInstance.put(`/api/APIResources/${id}`, values)
        else
            axiosInstance.post("/api/APIResources", values)
        console.log('Received values of form: ', values);
    };

    const elements = [
        // [{ col: 8, control: 'ControlInput', name: 'id', label: 'id', key: true },],
        [
            { col: 8, control: 'ControlInput', name: 'name', label: 'name' },
            { col: 8, control: 'ControlInput', name: 'displayName', label: 'displayName' },
            { col: 4, control: 'ControlCheckBox', name: 'enabled', label: 'enabled', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'showInDiscoveryDocument', label: 'showInDiscoveryDocument', valuePropName: "checked" },
        ],
        [
            { col: 16, control: 'ControlInput', name: 'description', label: 'description', multiline: true, rows: 4 },
            { col: 4, control: 'ControlCheckBox', name: 'nonEditable', label: 'nonEditable', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'requireResourceIndicator', label: 'requireResourceIndicator', valuePropName: "checked" },
        ],
        [
            {
                col: 24, control: 'List', name: 'secrets', label: 'Secrets',
                items: [
                    [
                        { col: 8, control: 'ControlInput', name: 'type', label: 'type' },
                        { col: 8, control: 'ControlInput', name: 'value', label: 'value' },
                        { col: 8, control: 'ControlDatePicker', name: 'expiration', label: 'expiration' },
                    ], [
                        { col: 24, control: 'ControlInput', name: 'description', label: 'description', multiline: true, rows: 4 },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'properties', label: 'Properties',
                items: [
                    [
                        { col: 12, control: 'ControlInput', name: 'key', label: 'key' },
                        { col: 12, control: 'ControlInput', name: 'value', label: 'value' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'allowedAccessTokenSigningAlgorithms', label: 'AllowedAccessTokenSigningAlgorithms',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'userClaims', label: 'UserClaims',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Type' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'scopes', label: 'Scopes',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
        ]
    ]

    return (<Fragment>
        <Create elements={elements} url={'/api/APIResources'}>
        </Create>
    </Fragment>
    )
};