
import { Menu } from 'antd'
import { Header } from 'antd/es/layout/layout'
import React from 'react'
import { Link } from 'react-router-dom'
import { sitesystem } from '../../config/sitesystem'
import { Tooltip } from '@mui/material'


export default function AppHeader(props) {

    const items = sitesystem.filter(x => x.link != window.location.origin).map((v, i) => {
        return {
            key: i,
            label: <Tooltip title={v.tooltip} placement='top' followCursor arrow>
                <a href={v.link} target="_blank" rel="noopener noreferrer">{v.title}</a>
            </Tooltip>,
            // icon: <MailOutlined />,
        }
    });

    const currentsite = sitesystem.find(x => x.link == window.location.origin)

    return (
        <div>
            <Header
                className={props.collapsed ? 'collapsed' : 'expanded'}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Link to={'/'}><div className="demo-logo" style={{ textAlign: 'center', lineHeight: '32px' }}>{currentsite?.title}</div></Link>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    // defaultSelectedKeys={['2']}
                    // items={items1}
                    style={{
                        flex: 1,
                        minWidth: 0,
                    }}
                    items={items}
                >
                    {/* {
                        sitesystem.map(x => {
                            return <Menu.Item key={x.title} >
                                <Tooltip title={x.tooltip}>
                                    <a href={x.link} target="_blank" rel="noopener noreferrer">{x.title}</a>
                                </Tooltip>
                            </Menu.Item>
                        })
                    } */}

                </Menu>
            </Header>
        </div>
    )
}
