// CustomDatePicker.jsx
import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Form, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';

export default function AppDatePicker({ value, ...props }) {

    return (
        <Fragment>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    // ref={ref}
                    value={value ? dayjs(value) : null}
                    {...props}
                    // label={null}
                    // renderInput={(params) =>
                    //    <></>
                    // }
                    slotProps={{ textField: { size: 'small', fullWidth: true } }}
                />
            </LocalizationProvider>
        </Fragment>

    );
}
