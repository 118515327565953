// src/auth/oidcConfig.js
import { UserManager, WebStorageStateStore } from 'oidc-client';

// Configuration object for OIDC
// const config = {
//   authority: process.env.REACT_APP_AUTHORITY,
//   client_id: process.env.REACT_APP_CLIENT_ID,
//   response_type: process.env.REACT_APP_RESPONSE_TYPE,
//   scope: process.env.REACT_APP_SCOPE,

//   redirect_uri: window.location.origin + "/callback",
//   post_logout_redirect_uri: process.env.REACT_APP_AUTHORITY + "/logout",

//   hrm: process.env.REACT_APP_HRM,
//   crm: process.env.REACT_APP_CRM,
//   sys: process.env.REACT_APP_SYS,
// };
var REACT_APP_SYS = process.env.REACT_APP_SYS;
var REACT_APP_CRM = process.env.REACT_APP_CRM;
var REACT_APP_HRM = process.env.REACT_APP_HRM;
let REACT_APP_AUTHORITY = process.env.REACT_APP_AUTHORITY;
var REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
var REACT_APP_RESPONSE_TYPE = process.env.REACT_APP_RESPONSE_TYPE;
var REACT_APP_SCOPE = process.env.REACT_APP_SCOPE;

// REACT_APP_SYS = 'https://apisys.thienvan.io.vn';
// REACT_APP_CRM = 'https://apicrm.thienvan.io.vn';
// REACT_APP_HRM = 'https://apicrm.thienvan.io.vn';
// REACT_APP_AUTHORITY = 'https://sso.thienvan.io.vn';
REACT_APP_CLIENT_ID = 'gen'
REACT_APP_RESPONSE_TYPE = 'code'
REACT_APP_SCOPE = 'openid profile'

const config = {
  authority: REACT_APP_AUTHORITY,

  client_id: REACT_APP_CLIENT_ID,
  response_type: REACT_APP_RESPONSE_TYPE,
  scope: REACT_APP_SCOPE,
  redirect_uri: `${window.location.origin}/callback`,
  post_logout_redirect_uri: `${window.location.origin}`,

  hrm: REACT_APP_HRM,
  crm: REACT_APP_CRM,
  sys: REACT_APP_SYS,
};

const userManager = new UserManager({ ...config, userStore: new WebStorageStateStore({ store: window.localStorage }) });

userManager.events.addUserLoaded((user) => {
  console.log("User loaded", user);
});

userManager.events.addUserUnloaded(() => {
  console.log("User logged out");
});

export default userManager;

