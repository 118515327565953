import React, { Fragment, useEffect, useState } from 'react';
import Create from '../../../components/Create';
import { Button, Col, Form, Row, Flex, Input } from 'antd';
import AppInput from '../../../components/Control/ControlInput';
import AppDatePicker from '../../../components/Control/ControlDatePicker';

const descProp = {
    Properties: 'Thuộc tính này chứa danh sách các đối tượng ClientProperty cho phép lưu trữ các thuộc tính tùy chỉnh liên quan đến client. Những thuộc tính này có thể được sử dụng để lưu trữ dữ liệu tùy chỉnh liên quan đến cấu hình của client.',
}

export default function CreateClient() {
    const elements = [
        [
            // { col: 8, control: 'ControlInput', name: 'id', label: 'id', key: true },
            { col: 8, control: 'ControlInput', name: 'clientId', label: 'clientId' },
            { col: 8, control: 'ControlInput', name: 'clientName', label: 'clientName' },
            { col: 4, control: 'ControlCheckBox', name: 'requireClientSecret', label: 'requireClientSecret', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'enabled', label: 'enabled', valuePropName: "checked" },
        ], [
            { col: 16, control: 'ControlInput', name: 'description', label: 'description', multiline: true, rows: 4 },
            { col: 4, control: 'ControlCheckBox', name: 'requireConsent', label: 'requireConsent', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'allowRememberConsent', label: 'allowRememberConsent', valuePropName: "checked" },
        ], [
            { col: 8, control: 'ControlInput', name: 'clientUri', label: 'clientUri' },
            { col: 8, control: 'ControlInput', name: 'logoUri', label: 'logoUri' },
            { col: 4, control: 'ControlCheckBox', name: 'alwaysIncludeUserClaimsInIdToken', label: 'alwaysIncludeUserClaimsInIdToken', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'requirePkce', label: 'requirePkce', valuePropName: "checked" },
        ], [
            { col: 8, control: 'ControlInput', name: 'frontChannelLogoutUri', label: 'frontChannelLogoutUri' },
            { col: 8, control: 'ControlInput', name: 'backChannelLogoutUri', label: 'backChannelLogoutUri' },
            { col: 4, control: 'ControlCheckBox', name: 'allowPlainTextPkce', label: 'allowPlainTextPkce', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'requireRequestObject', label: 'requireRequestObject', valuePropName: "checked" },
        ], [
            { col: 8, control: 'ControlInput', name: 'clientClaimsPrefix', label: 'clientClaimsPrefix' },
            { col: 8, control: 'ControlInput', name: 'pairWiseSubjectSalt', label: 'pairWiseSubjectSalt' },
            { col: 4, control: 'ControlCheckBox', name: 'allowAccessTokensViaBrowser', label: 'allowAccessTokensViaBrowser', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'requireDPoP', label: 'requireDPoP', valuePropName: "checked" },
        ], [
            { col: 8, control: 'ControlInput', name: 'initiateLoginUri', label: 'initiateLoginUri' },
            { col: 8, control: 'ControlInput', name: 'userCodeType', label: 'userCodeType' },
            { col: 4, control: 'ControlCheckBox', name: 'frontChannelLogoutSessionRequired', label: 'frontChannelLogoutSessionRequired', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'backChannelLogoutSessionRequired', label: 'backChannelLogoutSessionRequired', valuePropName: "checked" },
        ], [
            { col: 8, control: 'ControlInput', name: 'identityTokenLifetime', label: 'identityTokenLifetime', type: "number" },
            { col: 8, control: 'ControlInput', name: 'accessTokenLifetime', label: 'accessTokenLifetime' },
            { col: 4, control: 'ControlCheckBox', name: 'allowOfflineAccess', label: 'allowOfflineAccess', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'updateAccessTokenClaimsOnRefresh', label: 'updateAccessTokenClaimsOnRefresh', valuePropName: "checked" },
        ], [
            { col: 8, control: 'ControlInput', name: 'authorizationCodeLifetime', label: 'authorizationCodeLifetime', type: "number" },
            { col: 8, control: 'ControlInput', name: 'consentLifetime', label: 'consentLifetime' },
            { col: 4, control: 'ControlCheckBox', name: 'enableLocalLogin', label: 'enableLocalLogin', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'includeJwtId', label: 'includeJwtId', valuePropName: "checked" },
        ], [
            { col: 8, control: 'ControlInput', name: 'absoluteRefreshTokenLifetime', label: 'absoluteRefreshTokenLifetime', type: "number" },
            { col: 8, control: 'ControlInput', name: 'slidingRefreshTokenLifetime', label: 'slidingRefreshTokenLifetime' },
            { col: 4, control: 'ControlCheckBox', name: 'alwaysSendClientClaims', label: 'alwaysSendClientClaims', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'coordinateLifetimeWithUserSession', label: 'coordinateLifetimeWithUserSession', valuePropName: "checked" },
        ], [
            { col: 8, control: 'ControlInput', name: 'userSsoLifetime', label: 'userSsoLifetime', type: "number" },
            { col: 8, control: 'ControlInput', name: 'deviceCodeLifetime', label: 'deviceCodeLifetime' },
            { col: 4, control: 'ControlCheckBox', name: 'nonEditable', label: 'nonEditable', valuePropName: "checked" },
            { col: 4, control: 'ControlCheckBox', name: 'requirePushedAuthorization', label: 'requirePushedAuthorization', valuePropName: "checked" },
        ], [
            { col: 8, control: 'ControlInput', name: 'cibaLifetime', label: 'cibaLifetime', type: "number" },
            { col: 8, control: 'ControlInput', name: 'pollingInterval', label: 'pollingInterval' },
            { col: 8, control: 'ControlInput', name: 'pushedAuthorizationLifetime', label: 'pushedAuthorizationLifetime' },
        ], [
            { col: 8, control: 'ControlSelect', name: 'refreshTokenUsage', label: 'refreshTokenUsage', items: [{ value: 0, label: 'ReUse' }, { value: 1, label: 'OneTimeOnly' }] },
            { col: 8, control: 'ControlSelect', name: 'refreshTokenExpiration', label: 'refreshTokenExpiration', items: [{ value: 0, label: 'Sliding' }, { value: 1, label: 'Absolute' }] },
            { col: 8, control: 'ControlSelect', name: 'accessTokenType', label: 'accessTokenType', items: [{ value: 0, label: 'Jwt' }, { value: 1, label: 'Reference' }] },
        ], [
            {
                col: 24, control: 'List', name: 'clientSecrets', label: 'ClientSecrets',
                items: [
                    [
                        { col: 8, control: 'ControlInput', name: 'type', label: 'type' },
                        { col: 8, control: 'ControlInput', name: 'value', label: 'value' },
                        { col: 8, control: 'ControlDatePicker', name: 'expiration', label: 'expiration' },
                    ], [
                        { col: 24, control: 'ControlInput', name: 'description', label: 'description', multiline: true, rows: 4 },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'properties', label: 'Properties',
                items: [
                    [
                        { col: 12, control: 'ControlInput', name: 'key', label: 'key' },
                        { col: 12, control: 'ControlInput', name: 'value', label: 'value' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'claims', label: 'Claims',
                items: [
                    [
                        { col: 12, control: 'ControlInput', name: 'type', label: 'type' },
                        { col: 12, control: 'ControlInput', name: 'value', label: 'value' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'allowedGrantTypes', label: 'AllowedGrantTypes',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'redirectUris', label: 'RedirectUris',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'postLogoutRedirectUris', label: 'PostLogoutRedirectUris',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'allowedScopes', label: 'AllowedScopes',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'identityProviderRestrictions', label: 'IdentityProviderRestrictions',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'allowedCorsOrigins', label: 'AllowedCorsOrigins',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
            {
                col: 12, control: 'List', name: 'allowedIdentityTokenSigningAlgorithms', label: 'AllowedIdentityTokenSigningAlgorithms',
                items: [
                    [
                        { col: 24, control: 'ControlInput', label: 'Id' },
                    ]
                ]
            },
        ]
    ]
    return (<Fragment>
        <Create elements={elements} url={'/api/clients'}>

        </Create>
    </Fragment>
    )
}
