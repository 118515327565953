// src/Callback.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import userManager from '../auth/oidcConfig';

const Callback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    userManager.signinRedirectCallback().then(user => {
      console.log("User logged in", user);
      // Lấy URL đã lưu từ localStorage
      const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
      // Xóa URL khỏi localStorage
      localStorage.removeItem('redirectAfterLogin');

      // Điều hướng người dùng đến URL đã lưu hoặc về trang chính nếu không có URL lưu
      navigate(redirectAfterLogin || '/');
    }).catch(err => {
      console.log(err);
      userManager.signoutRedirect()
    });
  }, [navigate]);

  return <div>Loading...</div>;
};

export default Callback;
