// CustomDatePicker.jsx
import React, { forwardRef, Fragment, Suspense, useEffect, useState } from 'react';

export default function AppControl(props) {
    const { control, ...orderProps } = props;
    const [DynamicComponent, setDynamicComponent] = useState(null);

    useEffect(() => {
        // Dynamically import the component
        const loadComponent = async () => {
            const { default: LoadedComponent } = await import(`./${control}`);
            setDynamicComponent(() => LoadedComponent);
        };

        loadComponent();
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <Fragment>
            {DynamicComponent ? (
                <Suspense fallback={<div>Loading...</div>}>
                    <DynamicComponent {...orderProps} />
                </Suspense>
            ) : (
                <div>Loading component...</div>
            )}
        </Fragment>
    )
}
