import { Breadcrumb, Layout, Menu, Spin, theme } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import userManager from "../../auth/oidcConfig";
import { Content, Header } from "antd/es/layout/layout";
import { Link } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import AppHeader from "../header";

export default function AppLayoutSYS() {

    const items2 = [
        { label: 'ApiResource', link: '/ApiResource' },
        { label: 'ApiScope', link: '/ApiScope' },
        { label: 'Client', link: '/Client' },
        { label: 'IdentityProvider', link: '/IdentityProvider' },
        { label: 'IdentityResource', link: '/IdentityResource' },
    ].map((item, index) => {
        const key = String(index + 1);
        return {
            key: `sub${key}`,
            // icon: React.createElement(icon),
            label: <Link to={item.link}>{item.label}</Link>,
            // children: new Array(4).fill(null).map((_, j) => {
            //     const subKey = index * 4 + j + 1;
            //     return {
            //         key: subKey,
            //         label: `option${subKey}`,
            //     };
            // }),
        };
    });

    const location = useLocation();

    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [loadUser, setLoadUser] = useState(false);

    useEffect(() => {
        if (location.pathname != '/callback')
            userManager.getUser().then(user => {
                if (!user) {
                    userManager.signinRedirect()
                }
                else {
                    setLoadUser(true)
                }
            });
    }, [location.pathname, loadUser]);

    if (!loadUser && location.pathname != '/callback')
        return <Spin size="large" />

    return (
        <Layout>
            <AppHeader collapsed={collapsed} />

            <Layout>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
                    width={250}
                    style={{
                        background: colorBgContainer,
                    }}
                >
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={['1']}
                        // defaultOpenKeys={['sub1']}
                        style={{
                            height: '100%',
                            borderRight: 0,
                        }}
                        items={items2}
                    />
                </Sider>
                <Layout
                    style={{
                        padding: '0 24px 24px',
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                        }}
                        items={[{ title: 'Home' }, { title: 'List' }, { title: 'App' }]}
                    />

                    <Content
                        style={{
                            // padding: 24,
                            margin: 0,
                            minHeight: 'calc(100vh - 145px)',
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};