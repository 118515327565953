// src/App.js
import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Menu, Spin, theme } from 'antd';
import './App.css'
import Home from './page/home';
import Callback from './page/Callback';
import SysRoute from './page/sys';
import { AuthProvider } from './auth/AuthProvider';
import { AppLayout, AppLayoutGEN, AppLayoutSYS } from './components/layout';
import { sitesystem } from './config/sitesystem';
import GenRoute from './page/gen';
import HrmRoute from './page/hrm';
import axiosInstance from './api/axiosInstance';
import userManager from './auth/oidcConfig';
import QueryString from 'qs';
import axios from 'axios';

const SigninCallback = () => {
  useEffect(() => {
    userManager.signinRedirectCallback().then(() => {
      window.location = '/';
    });
  }, []);

  return <div>Loading...</div>;
};

const Con = () => {
  return (
    <Routes>
      {SysRoute()}
      <Route path="/callback" element={<Callback />} />
      {/* <Route path="/" element={<ProtectedPage />} /> */}
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

// export default App;

const App = () => {

  const introspectToken = async () => {
    try {
      const { access_token, id_token } = await userManager.getUser()

        try {
          const response = await axios.get(
            'https://sso.thienvan.io.vn/connect/userinfo',
            {
              headers: {
                'Authorization': `Bearer ${access_token}`
              }
            }
          );
          console.log("aaa",response);
        } catch (error) {
          console.error('Error fetching user info:', error.response ? error.response.data : error.message);
        }
    } catch (error) {
      console.error('Error introspecting token:', error.response ? error.response.data : error.message);
    }
  };

  useEffect(() => {
    introspectToken()

    return () => {

    }
  }, [])


  const currentsite = sitesystem.find(x => x.link == window.location.origin)

  return (
    <AuthProvider>
      <Routes>
        {currentsite?.title == 'SYS' && SysRoute()}
        {(currentsite?.title == 'GEN') && GenRoute()}
        {currentsite?.title == 'HRM' && HrmRoute()}
        {currentsite?.title == 'CRM' && SysRoute()}
        {
          currentsite == null &&
          <>
            <Route path="/" element={<AppLayout />}>
              <Route index element={<Home />} />
              <Route path="/callback" element={<Callback />} />
              {/* Add more routes here */}
            </Route>
          </>
        }
      </Routes>
    </AuthProvider>
  );
};
export default App;