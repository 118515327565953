// CustomDatePicker.jsx
import { TextField, Tooltip } from '@mui/material';
import { Form, Spin } from 'antd';
import React, { Fragment, useEffect, useState } from 'react';

export default function AppInput({ value, ...otherProps }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoading(true);
        }, 10)
    }, []);

    if (!loading) return <Spin />;

    return (
        <Fragment>
            <Tooltip title={otherProps.tooltip} followCursor arrow placement='top'>
                <TextField
                    {...otherProps}
                    fullWidth
                    size='small'
                    value={value ?? ''}
                />
            </Tooltip>
        </Fragment>
    )
}
