// src/Callback.js
import { Button, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../api/axiosInstance';
import ListData from '../../../components/List';
import { Link } from 'react-router-dom';


export default function APIScope() {
  
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
    render: (text,rc) => <Link to={`/ApiScope/${rc?.id}`}>{rc?.name}</Link>,
    width: '20%',
  },
  {
    title: 'displayName',
    dataIndex: 'displayName',
  },
  {
    title: <><Button><Link to={'/ApiScope/-1'}>Add</Link></Button></>,
    dataIndex: '',
    key: 'x',
    render: (text,rc) => <Button onClick={() => handleDelete(rc.id)}>Delete</Button>,
  },
];

  useEffect(() => {

  }, []);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const fetchData = () => {
    setLoading(true);

    const fetchData = async () => {
      try {
        // Example using apiClient1
        const response1 = await axiosInstance.get('/api/ApiScopes');
        console.log('Data from API 1:', response1.data);
        setData(response1.data); // or combine data as needed
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: 200,
            // 200 is mock data, you should read it from server
            // total: data.totalCount,
          },
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  
  };
  useEffect(() => {
    fetchData();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams?.sortOrder,
    tableParams?.sortField,
    JSON.stringify(tableParams.filters),
  ]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: Array.isArray(sorter) ? undefined : sorter.order,
      sortField: Array.isArray(sorter) ? undefined : sorter.field,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleDelete = async (id) => {
    await axiosInstance.delete(`/api/ApiScopes/${id}`)
  }

  return (
    <ListData columns={columns} urlListData={'/api/ApiScopes'} />
    // <Table
    //   columns={columns}
    //   rowKey={(record) => record.employeeId}
    //   dataSource={data}
    //   pagination={tableParams.pagination}
    //   loading={loading}
    //   onChange={handleTableChange}
    // />
  );
};
