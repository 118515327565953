const sitesystem = [
    {
        title: 'SYS',
        tooltip:'System Management',
        link:'https://sys.thienvan.io.vn'
    },
    {
        title: 'GEN',
        tooltip:'General Management',
        link:'https://gen.thienvan.io.vn'
    },
    {
        title: 'HRM',
        tooltip:'Human Resource Management',
        link:'https://hrm.thienvan.io.vn'
    },
    {
        title: 'CRM',
        tooltip:'Customer Relationship Management',
        link:'https://crm.thienvan.io.vn'
    }
    
];

export { sitesystem }