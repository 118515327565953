import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../api/axiosInstance';
import { Button, Card, Checkbox, Col, Flex, Form, Input, InputNumber, Row, Spin } from 'antd';
import AppControl from '../Control';
import { AddSquare, MinusSquare } from 'iconsax-react';

const descProp = {
  clientId: 'Mã định danh duy nhất của ứng dụng khách. Đây là giá trị dùng để xác định ứng dụng khi thực hiện yêu cầu đến máy chủ danh tính.',
  protocolType: 'Loại giao thức mà ứng dụng khách hỗ trợ, ví dụ như OpenID Connect hoặc OAuth 2.0.',
  clientName: 'Tên của ứng dụng khách. Đây là tên hiển thị để người dùng và quản trị viên có thể nhận diện ứng dụng.',
  description: 'Mô tả chi tiết về ứng dụng khách, giúp cung cấp thông tin bổ sung về mục đích và chức năng của ứng dụng.',
  clientUri: 'URL chính của ứng dụng khách. Đây có thể là trang web chính thức của ứng dụng.',
  logoUri: 'URL của logo ứng dụng khách. Đây là hình ảnh đại diện cho ứng dụng khách.',
  frontChannelLogoutUri: 'URL mà trình duyệt sẽ được chuyển hướng tới để thực hiện đăng xuất từ phía máy khách.',
  backChannelLogoutUri: 'URL mà máy chủ sẽ gọi để thực hiện đăng xuất mà không cần thông qua trình duyệt của người dùng.',
  allowedIdentityTokenSigningAlgorithms: 'Các thuật toán ký token danh tính (ID token) mà ứng dụng khách chấp nhận.',
  clientClaimsPrefix: 'Tiền tố được thêm vào các thuộc tính của client trong token.',
  pairWiseSubjectSalt: 'Mẫu khóa dùng để mã hóa thông tin chủ thể trong các ứng dụng client theo cách riêng biệt.',
  initiateLoginUri: 'URL mà ứng dụng khách sẽ chuyển hướng tới để bắt đầu quá trình đăng nhập.',
  userCodeType: 'Loại mã người dùng mà ứng dụng khách yêu cầu cho quá trình xác thực, ví dụ như mã OTP.',
  requireClientSecret: 'Chỉ định liệu ứng dụng khách có yêu cầu bí mật client (client secret) hay không.',
  enabled: 'Xác định xem ứng dụng khách có được kích hoạt hay không.',
  requireConsent: 'Chỉ định liệu người dùng có phải cung cấp sự đồng ý cho ứng dụng khách hay không.',
  allowRememberConsent: 'Cho phép người dùng chọn ghi nhớ sự đồng ý để không phải cung cấp lại trong tương lai.',
  alwaysIncludeUserClaimsInIdToken: 'Xác định liệu tất cả các thông tin về người dùng có được đưa vào ID token hay không.',
  requirePkce: 'Chỉ định liệu ứng dụng khách có yêu cầu PKCE (Proof Key for Code Exchange) trong quy trình xác thực không.',
  allowPlainTextPkce: 'Cho phép sử dụng PKCE với mật khẩu thông thường (không mã hóa).',
  requireRequestObject: 'Xác định liệu yêu cầu phải bao gồm một đối tượng yêu cầu (request object) hay không.',
  allowAccessTokensViaBrowser: 'Cho phép sử dụng access tokens qua trình duyệt web.',
  requireDPoP: 'Yêu cầu sử dụng DPoP (Demonstrating Proof of Possession) cho access tokens.',
  frontChannelLogoutSessionRequired: 'Chỉ định liệu phiên làm việc (session) của người dùng phải được yêu cầu trong quá trình đăng xuất qua kênh phía trước.',
  backChannelLogoutSessionRequired: 'Chỉ định liệu phiên làm việc của người dùng phải được yêu cầu trong quá trình đăng xuất qua kênh phía sau.',
  allowOfflineAccess: 'Cho phép ứng dụng khách yêu cầu quyền truy cập ngoại tuyến (offline access) để nhận refresh tokens.',
  updateAccessTokenClaimsOnRefresh: 'Xác định liệu các thuộc tính của access token có được cập nhật khi refresh token được sử dụng hay không.',
  enableLocalLogin: 'Cho phép người dùng đăng nhập cục bộ (local login) vào ứng dụng.',
  includeJwtId: 'Xác định liệu ID của JWT có được bao gồm trong token hay không.',
  alwaysSendClientClaims: 'Luôn luôn gửi các thuộc tính của client trong token.',
  coordinateLifetimeWithUserSession: 'Đồng bộ thời gian sống của token với phiên làm việc của người dùng.',
  nonEditable: 'Xác định liệu cấu hình của ứng dụng khách có thể chỉnh sửa được hay không.',
  requirePushedAuthorization: 'Yêu cầu sử dụng cơ chế pushed authorization (ủy quyền đã được đẩy).',
  identityTokenLifetime: 'Thời gian sống của ID token.',
  accessTokenLifetime: 'Thời gian sống của access token.',
  authorizationCodeLifetime: 'Thời gian sống của mã ủy quyền (authorization code).',
  consentLifetime: 'Thời gian sống của sự đồng ý (consent).',
  absoluteRefreshTokenLifetime: 'Thời gian sống tuyệt đối của refresh token.',
  slidingRefreshTokenLifetime: 'Thời gian sống của refresh token theo cơ chế trượt (sliding).',
  refreshTokenUsage: 'Cách sử dụng refresh token (ví dụ: sử dụng một lần hoặc nhiều lần).',
  refreshTokenExpiration: 'Thời gian hết hạn của refresh token.',
  accessTokenType: 'Loại access token, có thể là JWT hoặc reference token.',
  userSsoLifetime: 'Thời gian sống của Single Sign-On (SSO) cho người dùng.',
  deviceCodeLifetime: 'Thời gian sống của mã thiết bị (device code).',
  cibaLifetime: 'Thời gian sống của CIBA (Client Initiated Backchannel Authentication) token.',
  pollingInterval: 'Khoảng thời gian giữa các lần kiểm tra trạng thái trong cơ chế polling.',
  pushedAuthorizationLifetime: 'Thời gian sống của ủy quyền đã được đẩy (pushed authorization).',
  ClientSecrets: 'Thuộc tính này chứa danh sách các đối tượng ClientSecret, dùng để lưu trữ các bí mật (như mật khẩu hoặc khóa) để xác thực ứng dụng client với máy chủ danh tính.',
  AllowedGrantTypes: 'Thuộc tính này chứa danh sách các đối tượng ClientGrantType xác định các loại grant (cấp quyền) OAuth2 mà client được phép sử dụng. Các loại grant bao gồm mã ủy quyền (authorization code), thông tin đăng nhập của client (client credentials), implicit, v.v.',
  RedirectUris: 'Thuộc tính này chứa danh sách các đối tượng ClientRedirectUri chỉ định các URI mà máy chủ danh tính sẽ chuyển hướng người dùng đến sau khi xác thực. Những URI này rất quan trọng cho luồng mã ủy quyền (authorization code flow) và các luồng liên quan.',
  PostLogoutRedirectUris: 'Thuộc tính này chứa danh sách các đối tượng ClientPostLogoutRedirectUri chỉ định các URI mà người dùng sẽ được chuyển hướng đến sau khi họ đăng xuất. Điều này giúp cải thiện trải nghiệm người dùng bằng cách chuyển hướng người dùng đến vị trí mong muốn sau khi đăng xuất.',
  AllowedScopes: 'Thuộc tính này chứa danh sách các đối tượng ClientScope xác định các phạm vi (scopes) mà ứng dụng client được phép yêu cầu. Phạm vi xác định các tài nguyên mà client có thể truy cập thay mặt cho người dùng.',
  IdentityProviderRestrictions: 'Thuộc tính này chứa danh sách các đối tượng ClientIdPRestriction hạn chế các nhà cung cấp danh tính (như Google, Facebook) mà client được phép sử dụng để xác thực.',
  Claims: 'Thuộc tính này chứa danh sách các đối tượng ClientClaim xác định các claims (thông tin) mà nên được bao gồm trong các token cấp phát cho client. Claims có thể bao gồm vai trò người dùng, quyền hạn, và các thuộc tính khác.',
  AllowedCorsOrigins: 'Thuộc tính này chứa danh sách các đối tượng ClientCorsOrigin chỉ định các nguồn gốc (origins) nào được phép thực hiện các yêu cầu Cross-Origin Resource Sharing (CORS) từ ứng dụng client. Điều này giúp kiểm soát các miền có thể gửi yêu cầu đến máy chủ danh tính.',
  Properties: 'Thuộc tính này chứa danh sách các đối tượng ClientProperty cho phép lưu trữ các thuộc tính tùy chỉnh liên quan đến client. Những thuộc tính này có thể được sử dụng để lưu trữ dữ liệu tùy chỉnh liên quan đến cấu hình của client.',
}

const layout = {
  // labelCol: { span: 8 },
  // wrapperCol: { span: 16 },
};

export default function Create({ elements, children, url, props }) {

  const { id } = useParams(); // `id` is the parameter name defined in the route
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState({
    View: false,
    Edit: false,
    Create: false,
  })

  const onFinish = (values) => {
    mode.Edit && axiosInstance.put(`${url}/${id}`, values)
    mode.Create && axiosInstance.post(`${url}`, values)
    console.log('Received values of form: ', values);
  };
  useEffect(() => {
    if (id != "-1") {
      fetchData();
      handleModeChange('View')
    } else {
      handleModeChange('Create')
    }
  }, [id]);

  const fetchData = async () => {
    setLoading(true);
    const { data } = await axiosInstance.get(`${url}/${id}`)
    form.setFieldsValue(data)
    setLoading(false);
  }

  const handleModeChange = (newMode) => {
    setMode({
      View: false,
      Edit: false,
      Create: false,
      [newMode]: true
    });
  };

  const ExtraButton = () => {
    return <>
      {
        mode.View && <>
          <Button onClick={() => { handleModeChange('Edit') }}>Edit</Button>
          <Button onClick={() => { navigate(-1) }}>Back</Button>
        </>
      }
      {
        mode.Edit && <>
          <Button type="primary" htmlType="submit">Save</Button>
          <Button onClick={() => { navigate(0) }}>Cancel</Button>
        </>
      }
      {
        (mode.Create) && <>
          <Button type="primary" htmlType="submit">Save</Button>
          <Button onClick={() => { navigate(-1) }}>Back</Button>
        </>
      }
    </>
  }

  return (
    <Fragment>
      <Form
        {...layout}
        layout='vertical'
        style={{ margin: '12px' }}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
      >
        <Card title={mode.Create ? 'Create' : mode.View ? "View" : "Edit"} extra={<ExtraButton />} styles={{ body: { overflowY: 'auto', height: 'calc(100vmin - 223px)' } }}>
          {
            loading
              ? <Spin style={{ display: 'flex', justifyContent: 'center' }} tip="Loading..." spinning={loading}></Spin>
              :
              <Fragment>
                {elements?.map((v, i) => {
                  return <Fragment key={i}>
                    <Row gutter={24}>
                      {v?.map((vv, ii) => {
                        const { multiline, ...othervv } = vv;
                        if (vv.control == 'List') {
                          return <Fragment key={ii}>
                            {
                              (!vv.key || !mode.Create) && <Fragment>
                                <Col span={vv.col}>
                                  <Form.Item tooltip={descProp.ClientSecrets} label={<h3>{`${vv.label} List`}</h3>}>
                                    <Row gutter={24}>
                                      <Col span={24}>
                                        <Form.List name={vv.name}>
                                          {
                                            (fields, { add, remove }) => {
                                              return <Fragment>
                                                {
                                                  (mode.Create || mode.Edit) &&
                                                  <Row gutter={24}>
                                                    <Col span={vv.col == 24 ? 1 : 2}>
                                                      <Form.Item>
                                                        <AddSquare
                                                          onClick={() => add()}
                                                          size="32"
                                                          color="#198754"
                                                          variant="Outline"
                                                          style={{ display: 'flex', justifyContent: 'center', height: '40px', alignItems: 'center' }}
                                                        />
                                                      </Form.Item>
                                                    </Col>
                                                  </Row>
                                                }

                                                {
                                                  fields.map(({ key, name, fieldKey, ...restField }) => {
                                                    return <Fragment key={key}>
                                                      <Row gutter={24}>
                                                        {
                                                          (mode.Create || mode.Edit) &&
                                                          <Col span={vv.col == 24 ? 1 : 2}>
                                                            <MinusSquare
                                                              onClick={() => remove(name)}
                                                              size="32"
                                                              color="#dc3545"
                                                              variant="Outline"
                                                              style={{ display: 'flex', justifyContent: 'center', height: '40px', alignItems: 'center' }}
                                                            />
                                                          </Col>
                                                        }

                                                        <Col span={24 - (vv.col == 24 ? 1 : 2)}>
                                                          {
                                                            vv.items?.map((vvv, iii) => {
                                                              return <Fragment key={iii}>
                                                                <Row gutter={24}>

                                                                  <Col span={24}>
                                                                    <Row gutter={24}>
                                                                      {
                                                                        vvv?.map((vvvv, iiii) => {
                                                                          return <Fragment key={iiii}>
                                                                            <Col span={vvvv.col}>
                                                                              <Form.Item
                                                                                key={key}
                                                                                // label={`type ${name + 1}`} // Dynamic label based on index
                                                                                required={false}
                                                                              >
                                                                                <Form.Item
                                                                                  {...restField}
                                                                                  name={vvvv.name ? [name, vvvv.name] : [name]} // Path to the nested field 'b'
                                                                                  fieldKey={[fieldKey]}
                                                                                  noStyle
                                                                                >
                                                                                  <AppControl disabled={vvvv.key || mode.View} {...vvvv} mode={mode} />
                                                                                </Form.Item>
                                                                              </Form.Item>
                                                                            </Col>
                                                                          </Fragment>
                                                                        })
                                                                      }
                                                                    </Row>
                                                                  </Col>
                                                                </Row>
                                                              </Fragment>
                                                            })
                                                          }
                                                        </Col>
                                                      </Row>
                                                    </Fragment>
                                                  })
                                                }
                                              </Fragment>
                                            }
                                          }
                                        </Form.List>
                                      </Col>
                                    </Row>
                                  </Form.Item>
                                </Col>
                              </Fragment>
                            }
                          </Fragment>
                        }
                        return <Fragment key={`${i}_${ii}`}>
                          {
                            (!vv.key || !mode.Create) && <Fragment>
                              <Col span={vv.col}>
                                <Form.Item
                                  {...othervv}
                                  // name={vv.name}
                                  label={null}
                                >
                                  <AppControl disabled={vv.key || mode.View} {...vv} mode={mode} />
                                </Form.Item>
                              </Col>
                            </Fragment>
                          }
                        </Fragment>
                      })}
                    </Row>
                  </Fragment>
                })}
                {children}
              </Fragment>
          }
        </Card>
      </Form>
    </Fragment>
  );
};