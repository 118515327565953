import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Client from './Client';
import APIResource from './ApiResource';
import APIScope from './ApiScope';
import IdentityResource from './IdentityResource';
import IdentityProvider from './IdentityProvider';
import CreateClient from './Client/create';
import CreateIdentityProvider from './IdentityProvider/create';
import CreateIdentityResource from './IdentityResource/create';
import CreateAPIResource from './ApiResource/create';
import CreateAPIScope from './ApiScope/create';
import Home from '../home';
import Callback from '../Callback';
import { AppLayoutSYS } from '../../components/layout';

export default function SysRoute() {
    return <>
        <Route path="/" element={<AppLayoutSYS />}>
            <Route index element={<Home />} />
            <Route path="/callback" element={<Callback />} />
            <Route path="/APIResource/:id" element={<CreateAPIResource />} />
            <Route path="/APIResource" element={<APIResource />} />
            <Route path="/APIScope/:id" element={<CreateAPIScope />} />
            <Route path="/APIScope" element={<APIScope />} />
            <Route path="/client/:id" element={<CreateClient />} />
            <Route path="/client" element={<Client />} />
            <Route path="/IdentityProvider/:id" element={<CreateIdentityProvider />} />
            <Route path="/IdentityProvider" element={<IdentityProvider />} />
            <Route path="/IdentityResource/:id" element={<CreateIdentityResource />} />
            <Route path="/IdentityResource" element={<IdentityResource />} />
            {/* Add more routes here */}
        </Route>

    </>
};
